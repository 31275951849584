var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row container-query-insurance" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "row group-container pt-4 group-main" }, [
        _c("h5", { staticClass: "title-group" }, [_vm._v("แบบสอบถาม")]),
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "h2",
              { staticClass: "query-title text-center font-weight-bold mb-4" },
              [_vm._v("แบบสอบถามโรคร้ายแรง")]
            ),
            _vm._l(_vm.data.query, function (query, key) {
              return [
                _c("div", { key: key, staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-7 query-image" }, [
                    _c("img", { attrs: { src: query.image, alt: "" } }),
                  ]),
                  query.type == "checkbox"
                    ? _c(
                        "div",
                        { staticClass: "col-md-5 query-checkbox" },
                        [
                          query.text
                            ? _c("h4", { staticClass: "checkbox-title" }, [
                                _vm._v(_vm._s(query.text)),
                              ])
                            : _vm._e(),
                          _vm._l(
                            query.checkbox,
                            function (checkbox, keyCheckbox) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: keyCheckbox,
                                    staticClass: "form-group",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "custom-control custom-checkbox",
                                      },
                                      [
                                        _c("input", {
                                          staticClass: "custom-control-input",
                                          attrs: {
                                            id:
                                              "checkbox-for-" +
                                              key +
                                              "-" +
                                              keyCheckbox,
                                            type: "checkbox",
                                          },
                                        }),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "custom-control-label",
                                            attrs: {
                                              for:
                                                "checkbox-for-" +
                                                key +
                                                "-" +
                                                keyCheckbox,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(checkbox) +
                                                "\n                                        "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      )
                    : query.type == "text"
                    ? _c(
                        "div",
                        { staticClass: "col-md-5 query-checkbox" },
                        [
                          query.text
                            ? _c("h4", { staticClass: "checkbox-title" }, [
                                _vm._v(_vm._s(query.text)),
                              ])
                            : _vm._e(),
                          _vm._l(query.checkbox, function (text, keyText) {
                            return [
                              _c(
                                "div",
                                { key: keyText, staticClass: "form-group" },
                                [_c("h4", [_vm._v(_vm._s(text))])]
                              ),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._m(0, true),
                ]),
              ]
            }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }