<template>
    <div class="row container-query-insurance">
        <div class="col-lg-12">
            <div class="row group-container pt-4 group-main">
                <h5 class="title-group">แบบสอบถาม</h5>
                <div class="col-md-12">
                    <h2 class="query-title text-center font-weight-bold mb-4">แบบสอบถามโรคร้ายแรง</h2>
                    <template v-for="(query, key) in data.query">
                        <div :key="key" class="row">
                            <div class="col-md-7 query-image">
                                <img :src="query.image" alt="" />
                            </div>
                            <div v-if="query.type == 'checkbox'" class="col-md-5 query-checkbox">
                                <h4 v-if="query.text" class="checkbox-title">{{ query.text }}</h4>
                                <template v-for="(checkbox, keyCheckbox) in query.checkbox">
                                    <div :key="keyCheckbox" class="form-group">
                                        <div class="custom-control custom-checkbox">
                                            <input
                                                :id="'checkbox-for-' + key + '-' + keyCheckbox"
                                                type="checkbox"
                                                class="custom-control-input"
                                            />
                                            <label
                                                :for="'checkbox-for-' + key + '-' + keyCheckbox"
                                                class="custom-control-label"
                                            >
                                                {{ checkbox }}
                                            </label>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="col-md-5 query-checkbox" v-else-if="query.type == 'text'">
                                <h4 v-if="query.text" class="checkbox-title">{{ query.text }}</h4>
                                <template v-for="(text, keyText) in query.checkbox">
                                    <div :key="keyText" class="form-group">
                                        <h4>{{ text }}</h4>
                                    </div>
                                </template>
                            </div>
                            <div class="col-md-12"><hr /></div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from '@vue/composition-api';

export default {
    name: 'cowrie',
    components: {},
    setup() {
        const data = reactive({
            query: [
                {
                    image: '/media/image/query/1.png',
                    type: 'checkbox',
                    text: 'อะไรคือเป็นสาเหตุหลักที่คร่าชีวิตคนไทยอันดับ 1',
                    checkbox: ['โรคมะเร็ง', 'โรคหัวใจ', 'โรคปอด', 'อุบัติเหตุ'],
                },
                {
                    image: '/media/image/query/S__6848556.jpg',
                    type: 'text',
                    text: 'สาเหตุหลักที่ทำให้เกิดมะเร็ง มีดังนี้ครับ',
                    checkbox: ['1. อาหาร ปิ้งๆย่างๆ เป็นสารก่อให้เกิดโรคมะเร็ง'],
                },
                {
                    image: '/media/image/query/S__6848558.jpg',
                    type: 'text',
                    text: false,
                    checkbox: ['2. อารม เคลียดจากการทำงาน เคลียดจากเรื่องครอบครัว'],
                },
                {
                    image: '/media/image/query/S__6848560.jpg',
                    type: 'text',
                    text: false,
                    checkbox: ['3. อากาศ มลพิษจากควันบุหรี่ ควันธูป และควันรถยนต์'],
                },
                {
                    image: '/media/image/query/S__6848561.jpg',
                    type: 'text',
                    text: false,
                    checkbox: [
                        '4. กรรมพันธุ์ ผู้ที่มีเครือญาติเป็นโรคมะเร็ง มีโอกาส 30% ที่จะเป็นโรคมะเร็งตามกรรมพันธุ์',
                    ],
                },
                {
                    image: '/media/image/query/3.jpeg',
                    type: 'checkbox',
                    text: 'มีญาติพี่น้องเสียชีวิตจากโรคมะเร็งไหมครับ',
                    checkbox: ['มี', 'ยังไม่มี', 'ไม่ทราบ'],
                },
                {
                    image: '/media/image/query/w644.jpeg',
                    type: 'checkbox',
                    text: 'จากสถิติพบว่าคนไทย 1 ใน 4 คน เป็นมะเร็ง 1 คน คุณลูกค้าทราบไหมครับ',
                    checkbox: ['ทราบดี', 'ไม่ทราบเลย'],
                },
                {
                    image: '/media/image/query/5.png',
                    type: 'checkbox',
                    text: 'จากสถิติของกรมการแพทย์ปี 2565 พบว่า ค่ารักษาพยาบาลด้วยโรคมะเร็งต้องใช้เงินเริ่มต้นที่ 500,000-10,000,000 บาท คุณลูกค้าคิดว่าแพงไหมครับ',
                    checkbox: ['แพงมาก', 'ไม่แพง'],
                },
                {
                    image: '/media/image/query/6.webp',
                    type: 'checkbox',
                    text: 'ถ้าวันนั้นมาถึง คุณคิดว่า วิธีการรับมือที่เหมาะสมที่สุด ต้องทำอย่างไร',
                    checkbox: ['เก็บเงินที่ออมใว้มาใช้', 'ขายทรัพย์สินที่มีอยู่', 'ยืมญาติพี่น้อง', 'ขอรับบริจาค'],
                },
                {
                    image: '/media/image/query/7.webp',
                    type: 'checkbox',
                    text: 'ถ้าเลือกได้ระหว่างเราจ่ายค่ารักษาพยาบาลเอง กับบริษัทประกันจ่ายให้ คิดว่าเลือกอันใหนดีครับ',
                    checkbox: ['จ่ายเองทั้งหมด', 'บริษัทประกันจ่าย'],
                },
                {
                    image: '/media/image/query/8.jpeg',
                    type: 'checkbox',
                    text: 'ถ้ามีวิธีเตรียมเงิน 10 ล้านบาท เพียงใช้เงินหลักร้อย ลูกค้าว่าน่าสนใจไหมครับ',
                    checkbox: ['สนใจ', 'ไม่สนใจ'],
                },
            ],
        });

        return { data };
    },
};
</script>

<style lang="scss" scoped>
.query-title {
    color: #0073ff;
}
.query-image img {
    width: 95%;
    border-radius: 10px;
}
.checkbox-title {
    margin-top: 10px;
    margin-left: -10px;
}
.custom-checkbox {
    padding: 5px 10px;
    .custom-control-label {
        color: #34495e;
        margin-bottom: 0;
        font-size: 35px;
        font-weight: bold;
        padding-left: 40px;

        &::before {
            border: #33485e solid 5px;
        }
        &::before,
        &::after {
            top: -11px;
            width: 50px;
            height: 50px;
        }
    }

    .custom-control-input:checked ~ {
        .custom-control-label:before {
            background-color: #2c3e50;
        }
    }
}

@media (max-width: 992px) {
    .query-title {
        font-size: 30px;
    }
}
</style>
